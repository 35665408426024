















import { Component, Vue } from 'vue-property-decorator'
import vFreeApply from '@/components/v-free-apply.vue'
import cultureData from '../../assets/data/culture'
import vVideoModule from '@/components/v-video-module.vue'

@Component({
  components: { vFreeApply, vVideoModule }})
export default class extends Vue {
  private cultureData = cultureData.cultures

}
