export default {
	cultures: [{
			title: '2020黑谷儿童节',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/12bb99e3-17a4341fa8d-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/2020etj.jpg')
		},
		{
			title: '黑谷38女神节',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/4ef322de-17a434116a3-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/hg38nsj.jpg')
		},
		{
			title: '黑谷圣诞节',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/577936ba-17a4340e129-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/hgsdj.jpg')
		},
		{
			title: '2021黑谷儿童节',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/4186c23b-17a4340b6f5-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/2021etj.jpg')
		},
		{
			title: '黑谷七夕节',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/1620e38a-17a434076d9-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/hgqxj.jpg')
		},
		{
			title: '黑谷年会',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/72fc3eb-17a5a7cd2c2-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/hgnh.jpg')
		},
		{
			title: '共享推介会',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/54679af8-17a433de8a3-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/gdzmh.jpg')
		},
		{
			title: '黑谷公开课',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/47bf6f0e-17a433d437b-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/hggkk.jpg')
		},
		{
			title: '黑谷周年庆',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/27641ea3-17a31bc6c22-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/hgznq.jpg')
		},
		{
			title: '黑谷演唱会',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/bbcf67f-17a31bc5363-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/hgych.jpg')
		},
		{
			title: '黑谷荣誉篇',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/1d926ff0-17a31bc18f2-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/hgryp.jpg')
		},
		{
			title: '黑谷品牌升级',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/d0083a4-17a31bc1882-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/hgppsj.jpg')
		},
		{
			title: '黑谷乐活节合集',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/617bbc9-17a31ba4773-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/hglhj.jpg')
		},
		{
			title: '2020年中秋',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/178edfb6-17a31ab653b-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/2020zqj.jpg')
		},
		{
			title: '大咖到访',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/29efced5-17a31a91dbb-0005-502e-0e5-a9c95.mp4',
			poster: ''
		},
		{
			title: '行业盛会',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/181ab922-17a31a9bda4-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/hysh.jpg')
		},
		{
			title: '共享派对',
			src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/5b3d9dd2-17a31a8eaee-0005-502e-0e5-a9c95.mp4',
			poster: require('../images/culture/gxpd.jpg')
		}
	]
}
